<template>
  <v-form ref="form" v-model="valid">
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card>
        <v-card-title class="grey lighten-2">修正</v-card-title>
        <v-card-text class="pt-4">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12" v-if="products">
                <v-autocomplete
                  v-model="product"
                  :items="products"
                  color="blue-grey lighten-2"
                  label="Select"
                  item-text="title"
                  item-value="id"
                  dense
                  solo
                  @change="updateRegion(product)"
                  return-object
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="12" v-if="item.discount_product">
                <v-chip
                  class="ma-2"
                  close
                  v-for="(product, index) in item.discount_product"
                  :key="product.id"
                  @click:close="removeID(index)"
                >
                  {{ product.name }}
                </v-chip>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  label="Name"
                  solo
                  name="discount_name"
                  :rules="[rules.required]"
                  v-model="item.discount_name"
                  readonly
                  background-color="#e0e0e0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  label="Label"
                  solo
                  clearable
                  name="discount_label"
                  :rules="[rules.required]"
                  v-model="item.discount_label"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-select
                  :items="typeChoices"
                  name="discount_type"
                  label="Type"
                  solo
                  :rules="[rules.required]"
                  v-model="item.discount_type"
                  background-color="#e0e0e0"
                  append-icon=""
                  readonly
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  label="Amount"
                  solo
                  clearable
                  name="discount_amount"
                  :rules="[rules.required, rules.number]"
                  v-model="item.discount_amount"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small depressed @click="close" style="min-width: 90px">
            閉じる
          </v-btn>
          <v-btn
            class="mx-2"
            small
            depressed
            color="primary"
            @click="save"
            style="min-width: 90px"
          >
            保存する
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
export default {
  name: "EditDiscount",
  props: {
    dialog: Boolean,
    item: Object,
  },
  data: () => ({
    friends: null,
    product: null,
    products: null,
    valid: true,
    typeChoices: [
      { text: "Percent", value: "percent" },
      { text: "Fixed", value: "fixed" },
    ],
    rules: {
      required: (v) => !!v || "This field is required",
      number: (value) => {
        const pattern = /^[0-9]+$/;
        return pattern.test(value) || "Invalid amount.";
      },
    },
  }),
  mounted() {
    this.axios
      .get(process.env.VUE_APP_ROOT_API + "/discounts/products")
      .then((response) => {
        this.products = response.data;
        console.log(this.products);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    updateRegion(value) {
      // const t = this.item.discount_product.push(value);
      const t = {
        id: value.id,
        name: value.title,
      };
      // const c = this.item.discount_product.push(t);
      // if (this.item.discount_product.some((e) => e.id !== t.id)) {}
      if (this.item.discount_product.some((e) => e.id === t.id)) {
        console.log(this.item.discount_product);
      } else {
        this.item.discount_product.push(t);
      }
      console.log(this.item);
    },
    removeID(index) {
      this.item.discount_product.splice(index, 1);
    },
    save() {
      this.$refs.form.validate();
      console.log(this.item);
      if (this.valid) this.$emit("update", this.item);
    },
    close() {
      this.$emit("closeDialog", false);
    },
  },
};
</script>
