<template>
  <div class="p-dashboard">
    <v-container>
      <h1>Add Tags</h1>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  data: () => ({}),
  components: {},
  mounted() {},
  methods: {},
};
</script>
