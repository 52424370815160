<template>
  <div class="p-dashboard">
    <v-container>
      <v-card :loading="loading" class="mx-auto">
        <template slot="progress">
          <v-progress-linear color="deep-purple" height="3" indeterminate>
          </v-progress-linear>
        </template>
        <v-col>
          <v-row align="center">
            <v-card-title>ディスカウント一覧</v-card-title>
            <v-btn
              class="mx-2"
              small
              depressed
              color="primary"
              @click="openAddDialog"
            >
              <v-icon small dark>mdi-plus</v-icon>
              新規追加
            </v-btn>
          </v-row>
        </v-col>

        <v-simple-table fixed-header height="300px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">会員ランク</th>
                <th class="text-left">タグ名</th>
                <th class="text-left">商品</th>
                <th class="text-left">割引額</th>
                <th class="text-left">種別</th>
                <th></th>
              </tr>
            </thead>
            <tbody v-show="discounts">
              <tr v-for="item in discounts" :key="item.id">
                <td>{{ item.discount_label }}</td>
                <td>{{ item.discount_name }}</td>
                <td>
                  <v-chip
                    x-small
                    v-for="d in item.discount_product"
                    :key="d.id"
                  >
                    {{ d.name }}
                  </v-chip>
                </td>
                <td>{{ item.discount_amount }}</td>
                <td>{{ item.discount_type }}</td>
                <td>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    x-small
                    depressed
                    @click="openEditDialog(item)"
                  >
                    <v-icon small dark>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    x-small
                    @click="openDeleteDialog(item)"
                    depressed
                  >
                    <v-icon small dark>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-container>
    <AddDiscount
      :dialog="openAdd"
      @closeDialog="closeDialog"
      @add="addItem"
    ></AddDiscount>
    <EditDiscount
      :dialog="openEdit"
      @closeDialog="closeDialog"
      :item="editedItem"
      @update="updateItem"
    ></EditDiscount>
    <v-dialog v-model="openDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-center align-center justify-center"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog()"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteItem()">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AddDiscount from "../components/AddDiscount";
import EditDiscount from "../components/EditDiscount";

export default {
  name: "Home",
  data: () => ({
    loading: false,
    discounts: null,
    openAdd: false,
    openEdit: false,
    openDelete: false,
    editedItem: {},
    total: null,
  }),
  components: {
    AddDiscount,
    EditDiscount,
  },
  mounted() {
    this.loading = true;
    this.axios
      .get(process.env.VUE_APP_ROOT_API + "/discounts/products")
      .then((response) => {
        this.total = Object.keys(response.data).length;
      });
    this.axios
      .get(process.env.VUE_APP_ROOT_API + "/discounts/store/1")
      .then((response) => {
        this.discounts = response.data.map((v) => {
          return {
            discount_amount: v.discount_amount,
            discount_label: v.discount_label,
            discount_name: v.discount_name,
            discount_product: JSON.parse(v.discount_product),
            discount_type: v.discount_type,
            id: v.id,
            store_id: v.store_id,
          };
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => (this.loading = false));
  },
  methods: {
    addItem(data) {
      let that = this;
      const headers = {
        "Content-Type": "application/json",
      };
      console.log(data);
      this.axios
        .post(process.env.VUE_APP_ROOT_API + "/discounts", data, {
          headers: headers,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(function () {
          that.loading = false;
          that.closeDialog();
          that.updateData();
        });
    },

    updateData() {
      this.loading = true;
      this.axios
        .get(process.env.VUE_APP_ROOT_API + "/discounts/store/1")
        .then((response) => {
          this.discounts = response.data.map((v) => {
            return {
              discount_amount: v.discount_amount,
              discount_label: v.discount_label,
              discount_name: v.discount_name,
              discount_product: JSON.parse(v.discount_product),
              discount_type: v.discount_type,
              id: v.id,
              store_id: v.store_id,
            };
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },

    deleteItem() {
      let that = this;
      this.axios
        .delete(
          process.env.VUE_APP_ROOT_API + "/discounts/" + that.editedItem.id
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(function () {
          that.loading = false;
          that.closeDialog();
          that.updateData();
        });
    },

    updateItem(item) {
      let that = this;
      this.loading = false;
      that.closeDialog();
      item.discount_product = JSON.stringify(item.discount_product);
      const headers = {
        "Content-Type": "application/json",
      };
      this.axios
        .put(process.env.VUE_APP_ROOT_API + "/discounts/" + item.id, item, {
          headers: headers,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(function () {
          that.updateData();
        });
    },

    openDeleteDialog(item) {
      this.openDelete = true;
      this.editedItem = Object.assign({}, item);
    },

    openAddDialog() {
      this.openAdd = true;
    },

    openEditDialog(item) {
      this.openEdit = true;
      this.editedItem = Object.assign({}, item);
      console.log(item);
    },

    closeDialog(e) {
      this.openAdd = false;
      this.openEdit = false;
      this.openDelete = false;
      this.editedItem = {};
      console.log(e);
    },
  },
};
</script>
