<template>
  <v-form ref="form" v-model="valid">
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card>
        <v-card-title class="grey lighten-2">新規追加</v-card-title>
        <v-card-text class="pt-4">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12" v-if="products">
                <v-chip
                  class="ma-2"
                  close
                  v-for="(product, index) in products"
                  :key="product.id"
                  @click:close="removeID(index)"
                >
                  {{ product.title }}
                </v-chip>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  label="タグ名"
                  solo
                  clearable
                  name="discount_name"
                  :rules="[rules.required]"
                  v-model="item.discount_name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  label="会員ランク"
                  solo
                  clearable
                  name="discount_label"
                  :rules="[rules.required]"
                  v-model="item.discount_label"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-select
                  :items="typeChoices"
                  name="discount_type"
                  label="種別"
                  solo
                  :rules="[rules.required]"
                  v-model="item.discount_type"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  label="割引額"
                  solo
                  clearable
                  name="discount_amount"
                  :rules="[rules.required, rules.number]"
                  v-model="item.discount_amount"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small depressed @click="close" style="min-width: 90px">
            閉じる
          </v-btn>
          <v-btn
            class="mx-2"
            small
            depressed
            color="primary"
            @click="save"
            style="min-width: 90px"
          >
            保存する
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
export default {
  name: "AddDiscount",
  props: {
    dialog: Boolean,
  },
  data: () => ({
    valid: true,
    products: null,
    item: {
      discount_name: "",
      discount_label: "",
      discount_type: "",
      discount_amount: "",
      discount_product: "",
      store_id: "1",
    },
    typeChoices: [
      { text: "Percent", value: "percent" },
      { text: "Fixed", value: "fixed" },
    ],
    rules: {
      required: (v) => !!v || "こちらは必須項目です",
      number: (value) => {
        const pattern = /^[0-9]+$/;
        return pattern.test(value) || "Invalid amount.";
      },
    },
  }),
  mounted() {
    this.loading = true;
    this.axios
      .get(process.env.VUE_APP_ROOT_API + "/discounts/products")
      .then((response) => {
        this.products = response.data;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => (this.loading = false));
  },
  methods: {
    removeID(index) {
      this.products.splice(index, 1);
    },
    save() {
      this.$refs.form.validate();
      const idS = this.products.map((item) => {
        return {
          id: item.id,
          name: item.title,
        };
      });
      this.item.discount_product = JSON.stringify(idS);
      if (this.valid)
        this.$emit("add", { item: this.item, products: this.products });
    },
    close() {
      this.$emit("closeDialog", false);
    },
  },
};
</script>
